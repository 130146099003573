import gql from 'graphql-tag'


export const LIST_ASSET = (templateType) => gql`query LIST_ASSET ($assetType: String!, $q: FilterInput) {
  assets: listAsset${templateType} (assetType: $assetType, q: $q) {
    id code name
  }
}`

export const DETAIL_ASSET = (templateType) => gql`query DETAIL_ASSET ($assetType: String!, $assetId: Int!) {
  asset: detailAsset${templateType} (assetType: $assetType, assetId: $assetId) {
    id code name
  }
}`

export const CREATE_ASSET = (templateType) => gql`mutation CREATE_ASSET ($assetType: String!, $input: Asset${templateType}Input!) {
  createAsset: createAsset${templateType} (assetType: $assetType, input: $input) {
    id code name
  }
}`

export const UPDATE_ASSET = (templateType) => gql`mutation UPDATE_ASSET ($assetType: String!, $assetId: Int!, $input: Asset${templateType}Input!) {
  updateAsset: updateAsset${templateType} (assetType: $assetType, assetId: $assetId, input: $input) {
    id code name
  }
}`

export const DESTROY_ASSET = (templateType) => gql`mutation DESTROY_ASSET ($assetType: String!, $assetId: Int!) {
  destroyAsset: destroyAsset${templateType} (assetType: $assetType, assetId: $assetId) {id}
}`
