import gql from 'graphql-tag'


export const LIST_ATTACHMENT = (templateType) => gql`query LIST_ATTACHMENT ($assetType: String!, $type: String!, $assetId: Int!) {
  items: listAsset${templateType}Attachment (assetType: $assetType, type: $type, assetId: $assetId) {
    id filename thumbnail
  }
}`

export const DETAIL_ATTACHMENT = (templateType) => gql`query DETAIL_ATTACHMENT ($assetType: String!, $type: String!, $attachmentId: Int!, $isDownload: Boolean) {
  url: detailAsset${templateType}Attachment (assetType: $assetType, type: $type, attachmentId: $attachmentId, isDownload: $isDownload)
}`

export const CREATE_ATTACHMENT = (templateType) => gql`mutation CREATE_ATTACHMENT ($assetType: String!, $type: String!, $assetId: Int!, $input: Asset${templateType}AttachmentInput!) {
  createItem: createAsset${templateType}Attachment (assetType: $assetType, type: $type, assetId: $assetId, input: $input)
}`

export const DESTROY_ATTACHMENT = (templateType) => gql`mutation DESTROY_ATTACHMENT ($assetType: String!, $type: String!, $attachmentId: Int!) {
  deleteItem: destroyAsset${templateType}Attachment (assetType: $assetType, type: $type, attachmentId: $attachmentId)
}`
