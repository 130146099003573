<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-table
      :rKey="rKey"
      :items="assets"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.code">
            <router-link
              class="text-decoration-none"
              :to="toDetail(item.id)">
              {{item.code}}
            </router-link>
          </td>
          <td v-if="hidden.name">
            {{item.name}}
          </td>
        </tr>
      </template>

      <router-link
        slot="action"
        v-if="$auth.hasRole(`asset:${assetType}:add`)"
        :to="toDetail(0)">
        <button
          type="button"
          class="btn btn-link text-success">
          เพิ่ม
        </button>
      </router-link>
    </sgv-table>
  </div>
</template>

<script>
import { LIST_ASSET } from './graph'
import retainMixin from '@/mixins/retain-mixin'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `Asset${this.$form.capitalize(this.assetType)}Detail`,
      rKey: `Asset${this.$form.capitalize(this.assetType)}List`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'ชื่อ', value: 'name', sort: true, filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      assets: [],
    }
  },
  apollo: {
    assets: {
      query () {
        return LIST_ASSET(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          assetType: this.assetType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      return {
        ...v,
        params: v.params
      }
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {assetId: id}
      }
    },
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
